import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, Typography, Grid, Avatar } from '@mui/material';
import { styled } from '@mui/system';
import {
  AssessmentRounded,
  DonutLargeRounded
} from "@mui/icons-material";

const StyledCard = styled(Card)({
  boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)',
  transition: 'box-shadow 0.3s ease-in-out',
  '&:hover': {
    // Updated hover styles
   
    cursor: 'pointer',
    backgroundColor: 'lightblue', // Change the background color on hover
    color: '#000000', // Change the text color on hover
  },
  margin:"10px",
  height: '100px',
  backgroundColor:"rgb(255,255,255)"
});

const StyledCardContent = styled(CardContent)({
  padding: '20px',
});

function Menu() {
  const navigate = useNavigate();

  const handleCardClick = (path) => {
    navigate(path);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
        <StyledCard onClick={() => handleCardClick('/stats')}>
          <StyledCardContent>
            <Grid container alignItems="center">
              <Grid item xs={3} sm={2}>
                <Avatar sx={{ bgcolor: 'primary.main', width: 48, height: 48 }}>
                  <AssessmentRounded/>
                </Avatar>
              </Grid>
              <Grid item xs={9} sm={10}>
                <Typography variant="h5" align="center">
                  PDMP Usage Metrics (OD2A Metrics)
                </Typography>
              </Grid>
            </Grid>
          </StyledCardContent>
        </StyledCard>
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
        <StyledCard onClick={() => handleCardClick('/bja')}>
          <StyledCardContent>
            <Grid container alignItems="center">
              <Grid item xs={3} sm={2}>
                <Avatar sx={{ bgcolor: 'primary.main', width: 48, height: 48 }}>
                  <DonutLargeRounded/>
                </Avatar>
              </Grid>
              <Grid item xs={9} sm={10}>
                <Typography variant="h5" align="center">
                  PDMP BJA Metrics
                </Typography>
              </Grid>
            </Grid>
          </StyledCardContent>
        </StyledCard>
      </Grid>
      {/* <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
        <StyledCard onClick={() => handleCardClick('/mes')}>
          <StyledCardContent>
            <Grid container alignItems="center">
              <Grid item xs={3} sm={2}>
                <Avatar sx={{ bgcolor: 'primary.main', width: 48, height: 48 }}>
                  <DonutLargeRounded/>
                </Avatar>
              </Grid>
              <Grid item xs={9} sm={10}>
                <Typography variant="h5" align="center">
                CMS Scorecard (MES Metrics)
                </Typography>
              </Grid>
            </Grid>
          </StyledCardContent>
        </StyledCard>
      </Grid> */}

      {/* <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
        <StyledCard>
          <StyledCardContent>
            <Grid container alignItems="center">
              <Grid item xs={3} sm={2}>
                <Avatar sx={{bgcolor: 'primary.main', width: 48, height: 48 }}>
                <AssessmentRounded/>
                </Avatar>
              </Grid>
              <Grid item xs={9} sm={10}>
                <Typography variant="h5" align="center">
                PDMP Historical Lookups
                </Typography>
              </Grid>
            </Grid>
          </StyledCardContent>
        </StyledCard>
      </Grid> */}
      {/* Repeat the same structure for other cards */}
      {/* <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
        <StyledCard>
          <StyledCardContent>
            <Grid container alignItems="center">
              <Grid item xs={3} sm={2}>
                <Avatar sx={{ bgcolor: 'primary.main', width: 48, height: 48 }}>
                <DonutLargeRounded/>
                </Avatar>
              </Grid>
              <Grid item xs={9} sm={10}>
                <Typography variant="h5" align="center">
                PDMP Compliance
                </Typography>
              </Grid>
            </Grid>
          </StyledCardContent>
        </StyledCard>
      </Grid> */}
    </Grid>
  );
}

export default Menu;
