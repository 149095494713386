import React from "react";
import { Grid, Paper, Typography, TableContainer } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useGetSetData } from "../../services/useGetSetHook";

const BjaMetrics1 = () => {
  // Fetch monthly data
  const { filteredData: monthlyData, datePickersComponent: monthlyDatePicker } =
    useGetSetData(process.env.REACT_APP_BASE_API + "/monthly_data", false);

  // Fetch quarterly data
  const { filteredData: quarterlyData, datePickersComponent: quarterlyDatePicker } =
    useGetSetData(process.env.REACT_APP_BASE_API + "/quarterly_data", false);

  // Monthly Table Config
  const bjaMetricsColumns = [
    { field: "date", headerName: "Month", flex: 1 },
    { field: "licensedPrescribers", headerName: "Licensed Prescribers", flex: 1 },
    { field: "registeredPrescribers", headerName: "Registered Prescribers", flex: 1 },
    { field: "licensedPharmacies", headerName: "Licensed Pharmacies", flex: 1 },
    { field: "registeredPharmacies", headerName: "Registered Pharmacies", flex: 1 },
    { field: "dataSharing", headerName: "Data Sharing Systems", flex: 1 },
    { field: "entitiesIntegrated", headerName: "Entities Integrated", flex: 1 },
    { field: "reportsProduced", headerName: "Reports Produced", flex: 1 },
    { field: "lawEnforcementUsers", headerName: "Law Enforcement Users", flex: 1 },
    { field: "investigatorsAccess", headerName: "Investigators Access", flex: 1 },
  ];

  // Quarterly Table Config
  const atLeastOnePrescription = [
    { field: "quarter", headerName: "Period", flex: 1 },
    { field: "prescriberCount", headerName: "Prescriber Count", flex: 1 },
  ];

  const patientsWithMme = [
    { field: "quarter", headerName: "Period", flex: 1 },
    { field: "prescriberCount", headerName: "Prescriber Count", flex: 1 },
  ];

  const patientsSchduledDrugs = [
    { field: "quarter", headerName: "Period", flex: 1 },
    { field: "prescriberCount", headerName: "Prescriber Count", flex: 1 },
  ];

  const multiplePrescribers = [
    { field: "quarter", headerName: "Period", flex: 1 },
    { field: "prescriberCount", headerName: "Prescriber Count", flex: 1 },
  ];


  const renderTable = (title, data, columns, picker) => (
    <Grid item xs={12}>
      <Paper sx={{ padding: 2, backgroundColor: "#e0f7fa" }}>
        <Typography sx={{ textAlign: "center", fontWeight: "bold" }}>
          {title}
        </Typography>
      </Paper>
      {picker}
      <TableContainer component={Paper} sx={{ maxHeight: "600px", marginTop: 2 }}>
        <DataGrid
          rows={data}
          columns={columns}
          size="small"
          density="compact"
          hideFooter
        />
      </TableContainer>
    </Grid>
  );

  return (
    <Grid container spacing={2}>
      {/* Monthly Table */}
      {renderTable("PDMP BJA Metrics", monthlyData, bjaMetricsColumns, monthlyDatePicker)}

      {/* Quarterly Table */}
      {renderTable("Number of Prescribers that wrote at least 1 prescription for a controlled substance during the 3- month reporting period", quarterlyData, atLeastOnePrescription, quarterlyDatePicker)}

      {renderTable("Number of Patients with MME > 90", quarterlyData, patientsWithMme, quarterlyDatePicker)}

      {renderTable("Any patients (youth and adults) were prescribed the following scheduled drugs (non-liquid) in 3-month reporting period", quarterlyData, patientsSchduledDrugs, quarterlyDatePicker)}

      {renderTable("Number of Patients with Multiple Prescriber and Pharmacies", quarterlyData, multiplePrescribers, quarterlyDatePicker)}

    </Grid>
  );
};

export default BjaMetrics1;
