import React from "react";
import Box from "@mui/material/Box";
import BjaMetrics1 from "../components/bjaMetrics/BjaMetrics1";

const BjaMetrics = () => {
  return (
    <div className="login-page">
      <Box sx={{ flexGrow: 1, bgcolor: "background.paper", height: "auto" }}>
           <BjaMetrics1/>     
      </Box>
    </div>
  );
};

export default BjaMetrics;
